<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-app>
      <transition>
        <router-view />
      </transition>
    </v-app>
    <!--logitem refers to service-->
    <!--logitem refers to service-->
    <!--slotlog selectedslotLog to eventSlot-->
  </div>

</template>


<script>
import axios from "axios";
import {defineAsyncComponent} from 'vue'


export default {
  name: "App",
  components: {},
  metaInfo: {
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { name: 'keywords', content: 'Shri Mahalaxmi Mandir, Sarasbaug Pune,Shri Mahalaxmimata,Shrisukta Abhishek,Navaratri Havan,Navaratri Abhishek,Prasad,Aarti,Bramha Utsav,Navaratri Pooja'},
      { name: 'author', content: 'Umesh,Shivam,Pankaj,Aditi'},
      { name: 'description', content: 'This temple represents the single spot where darshan can be obtained of all the three forms of Adi Shakti or the Divine Mother. Sri Mahasaraswati the Goddess of learning, Sri Mahalaxmi the Goddess of prosperity and Sri Mahakaali, the Goddess who liberates mortals from time and death. All the deities embody all the three gunas of tamas, rajas and sattva and are all present in this temple for the worship and adoration of devotees.'}, 
    ]
  },
  data: () => ({
    isClosed: true,
    dialog: false,
    quick_links: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    quicklinkslist: null,
    all_role_links: null,
    add_link: []
  }),
  methods: {},
  watch: {
    '$route' (to, from) {
      if(to.params.producturl){
        document.title = 'Shri Mahalaxami Mandir, Sarasbag Pune'
      }
      else{
        document.title = 'Shri Mahalaxami Mandir, Sarasbag Pune'
      }
    }
  },
};
</script>
<style scoped>
.quick-link-slider-box {
  border: 1px solid #0000001f;
  position: fixed;
  top: 9%;
  right: -256px;
  width: 256px;
  height: 91%;

  background-color: white;
  transition-duration: 0.1s;
  transition-delay: 0.2s;
}
.theme--light.v-icon {
  color: orange;
}
.quick-link-icon {
  text-align: center;
  width: 28px;
  height: 28px;
  position: relative;
  left: -28px;

  transition-duration: 0.1s;
  transition-delay: 0.2s;
}
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
.quick-link-add-btn {
  position: absolute;
  right: 4px;
  top: 8px;
}
@media print {
  body {
    height: 100%;
    width: 100%;
  }
  .no-display{
    display: none !important;
  }
}
</style>
