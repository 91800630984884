import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import { routes } from './routes'
import { store } from './store/store'
import axios from 'axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuelidate from 'vuelidate'
import JsonExcel from 'vue-json-excel'
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);
import "@/plugins/echarts";
import DatetimePicker from 'vuetify-datetime-picker'
//(Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)
Vue.component('downloadExcel', JsonExcel)
Vue.use(Vuelidate)
Vue.use(VueRouter);

import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

import VueMathjax from 'vue-mathjax'
Vue.use(VueMathjax)

const router = new VueRouter({
    routes: routes,  //routes:routes
    mode: 'history', //history to remove #
    //linkActiveClass: 'tag is-active',
})

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API_URL //"http://localhost:8080"
axios.interceptors.request.use(config => {
    // console.log("req-int"+process.env.VUE_APP_API_URL)
    if (!navigator.onLine) {
        alert("You are offline..!")
    }
    if (localStorage.getItem("EPC-uid"))
        config.headers.common['uid'] = localStorage.getItem("EPC-uid")
     
    config.headers.common['router-path'] = router.currentRoute.path
    return config
})
axios.interceptors.response.use(res => {
    return res
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)&&to.meta.userType=="Learner"){
        if(localStorage.getItem("loginid")){
            next();
        }
        else{
            next({
                path: '/'
            });
        }
    }
    else{
        next();
    }
  });

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default router;