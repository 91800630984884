const index = () => import("./components/index.vue");
const Ghatasthapana = () => import("./components/ghatasthapana.vue");
// const Ghatasthapana = () =>import ('./components/ghatasthapana.vue');
const Confirm = () => import("./components/confirm.vue");

//Shared Folder Paths
const Login = () => import("./components/shared/Login.vue");
const MandirDashboard = () => import("./components/shared/MandirDashboard.vue");
const Home = () => import("./components/shared/Home.vue");
const ChangePassword = () => import("./components/shared/ChangePassword.vue");
const ForgotPassword = () => import("./components/shared/ForgotPassword.vue");
const header = () => import("./components/shared/AdminHeader.vue");
//end

//Masters
const FormFieldMaster = () =>
  import("./components/Masters/FormFieldMaster.vue");
const Masters = () => import("./components/Masters/Masters.vue");
const FormConfiguration = () =>
  import("./components/Masters/FormConfiguration.vue");
const ServiceMethod = () => import("./components/Masters/ServiceMethod.vue");
const Service = () => import("./components/Masters/Service.vue");
const EventMaster = () => import("./components/Masters/EventMaster.vue");
const Slot = () => import("./components/Masters/Slot.vue");
const ServiceType = () => import("./components/Masters/ServiceType.vue");
const FinancialYear = () => import("./components/Masters/FinancialYear.vue");
const SeatingRow = () => import("./components/Masters/SeatingRow.vue");
const SeatingColumn = () => import("./components/Masters/SeatingColumn.vue");
const SeatingMatrix = () => import("./components/Masters/SeatingMatrix.vue");
const SeatingStatus = () => import("./components/Masters/SeatingStatus.vue");
const ReceiptTracking = () =>
  import("./components/Masters/ReceiptTracking.vue");
const Currency = () => import("./components/Masters/Currency.vue");
const PaymentMode = () => import("./components/Masters/PaymentMode.vue");
const Bank = () => import("./components/Masters/Bank.vue");
const TransactionStatus = () =>
  import("./components/Masters/TransactionStatus.vue");
const ServiceOfferingType = () =>
  import("./components/Masters/ServiceOfferingType.vue");

//end

//Admin & Super Admin Links
const OfferServicesAndrates = () =>
  import("./components/SuperAdmin/OfferServicesAndrates.vue");
const Event = () => import("./components/SuperAdmin/Event.vue");

//end
const redirect = () => import("./components/redirect.vue");

//client
const Navratri = () => import("./components/Client/Navratri.vue");
const chooseats = () => import("./components/Client/chooSeats.vue");
const contactDeatils = () => import("./components/Client/EnterUserDetails.vue");
const PaymentFailure = () => import("./components/Client/PaymentFailure.vue");
const choosePrasad = () => import("./components/Client/choosePrasad.vue");
const CartPayFees = () => import("./components/Client/CartPayFees.vue");
const PaymentSuccessWithNonAllocation = () =>
  import("./components/Client/PaymentSuccessWithNonAllocation.vue");
const templeSeva = () => import("./components/Client/templeSeva.vue");
const templeSevaUserDetails = () =>
  import("./components/Client/templeSevaUserDetails.vue");
const donation = () => import("./components/Client/Donation.vue");
//end

//Reports
const EventReport = () => import("./components/Admin/Reports/EventReport.vue");
const DateWiseConsolidatedReport = () =>
  import("./components/Admin/Reports/DateWiseConsolidatedReport.vue");
//end

//Booking
const Adminbooking = () =>
  import("./components/Admin/Booking/Adminbooking.vue");
const adminChooseats = () =>
  import("./components/Admin/Booking/adminChooseats.vue");
const AdminAddPaymentDetails = () =>
  import("./components/Admin/Booking/AdminAddPaymentDetails.vue");
const AdminReceipt = () =>
  import("./components/Admin/Booking/AdminReceipt.vue");
const ReserveSeatBooking = () =>
  import("./components/Admin/Booking/ReserveSeatBooking.vue");
//End
//Communication
const SendEventWhatsapp = () =>
  import("./components/Admin/Communication/SendEventWhatsapp.vue");

//End

export const routes = [
  // {
  //   path: "/header",
  //   name: "header",
  //   component: header,
  // },
  {
    path: "/",
    name: "redirect",
    component: redirect,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/choose-event",
    name: "chooseevent",
    component: index,
  },
  {
    path: "/choose-seats",
    name: "chooseats",
    component: chooseats,
  },
  {
    path: "/contact-details",
    name: "contactDeatils",
    component: contactDeatils,
  },
  {
    path: "/payment-failure",
    name: "PaymentFailure",
    component: PaymentFailure,
  },
  {
    path: "/Confirm-payment-nonallocation",
    name: "PaymentSuccessWithNonAllocation",
    component: PaymentSuccessWithNonAllocation,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },

  {
    path: "/ghatasthapana",
    name: "ghatasthapana",
    component: Ghatasthapana,
  },
  {
    path: "/navratri",
    name: "Navratri",
    component: Navratri,
  },
  {
    path: "/confirm",
    name: "/confirm",
    component: Confirm,
  },
  {
    path: "/choose-prasad",
    name: "choosePrasad",
    component: choosePrasad,
  },
  {
    path: "/donation",
    name: "donation",
    component: donation,
  },
  {
    path: "/temple-seva",
    name: "templeSeva",
    component: templeSeva,
  },
  {
    path: "/temple-seva-details",
    name: "templeSevaUserDetails",
    component: templeSevaUserDetails,
  },
  {
    path: "/cart-payment",
    name: "CartPayFees",
    component: CartPayFees,
  },
  {
    path: "/booking/view-receipt",
    name: "AdminReceipt",
    component: AdminReceipt,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    children: [
      {
        path: "/mandir-dashboard",
        name: "MandirDashboard",
        component: MandirDashboard,
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
      },
      {
        path: "/offer-services",
        name: "OfferServicesAndrates",
        component: OfferServicesAndrates,
      },
      {
        path: "/event",
        name: "Event",
        component: Event,
      },
      {
        path: "/masters",
        name: "Masters",
        component: Masters,
      },
    ],
  },
  {
    path: "/reports",
    name: "home",
    component: Home,
    children: [
      {
        path: "event-report",
        name: "EventReport",
        component: EventReport,
      },
      {
        path: "datewise-consolideted-report",
        name: "DateWiseConsolidatedReport",
        component: DateWiseConsolidatedReport,
      },
    ],
  },
  {
    path: "/communication",
    name: "home",
    component: Home,
    children: [
      {
        path: "Send-event-notification",
        name: "SendEventWhatsapp",
        component: SendEventWhatsapp,
      },
    ],
  },
  {
    path: "/booking",
    name: "home",
    component: Home,
    children: [
      {
        path: "book-event",
        name: "Adminbooking",
        component: Adminbooking,
      },
      {
        path: "choose-seats",
        name: "adminChooseats",
        component: adminChooseats,
      },
      {
        path: "add-payment-details",
        name: "AdminAddPaymentDetails",
        component: AdminAddPaymentDetails,
      },
      {
        path: "confirm-reserve-seats",
        name: "ReserveSeatBooking",
        component: ReserveSeatBooking,
      },
    ],
  },
  {
    path: "/master",
    name: "home",
    component: Home,
    children: [
      {
        path: "form-field-master",
        name: "FormFieldMasters",
        component: FormFieldMaster,
      },
      {
        path: "form-configuration",
        name: "FormConfiguration",
        component: FormConfiguration,
      },
      {
        path: "service-method",
        name: "servicemethod",
        component: ServiceMethod,
      },
      {
        path: "service",
        name: "service",
        component: Service,
      },
      {
        path: "event-master",
        name: "eventmaster",
        component: EventMaster,
      },
      {
        path: "slot",
        name: "slot",
        component: Slot,
      },
      {
        path: "service-type",
        name: "servicetype",
        component: ServiceType,
      },
      {
        path: "financial-year",
        name: "financialyear",
        component: FinancialYear,
      },
      {
        path: "seating-row",
        name: "seatingrow",
        component: SeatingRow,
      },
      {
        path: "seating-col",
        name: "seatingcolumn",
        component: SeatingColumn,
      },
      {
        path: "seating-matrix",
        name: "seatingmatrix",
        component: SeatingMatrix,
      },
      {
        path: "seating-status",
        name: "seatingstatus",
        component: SeatingStatus,
      },
      {
        path: "receipt-tracking",
        name: "receipttracking",
        component: ReceiptTracking,
      },
      {
        path: "currency",
        name: "currency",
        component: Currency,
      },
      {
        path: "payment-mode",
        name: "paymentmode",
        component: PaymentMode,
      },
      {
        path: "bank",
        name: "bank",
        component: Bank,
      },
      {
        path: "transaction-Status",
        name: "transactionStatus",
        component: TransactionStatus,
      },
      {
        path: "service-offering-type",
        name: "serviceofferingtype",
        component: ServiceOfferingType,
      },
    ],
  },
];
export default routes;
